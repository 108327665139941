import { CreditType } from '@kazoo/shared'
import { notification } from 'antd'
import { FormikProvider, useFormik } from 'formik'
import { Form, Input, InputNumber, Select, SubmitButton } from 'formik-antd'
import React from 'react'
import * as yup from 'yup'
import { useAppData } from '../../hooks/use-app-data'
import { api } from '../../services/api'

interface AddCreditsFormData {
    userId: string
    creditType: CreditType
    amount: number
    comment: string
}

const addCreditsYupSchema = yup.object<AddCreditsFormData>().shape({
    userId: yup.string().required('User required'),
    creditType: yup.string().required('Credit type required'),
    amount: yup.number().required('Amount required').min(1, 'Min is 1').max(20, 'Max is 20'),
    comment: yup.string().required('Add a comment / description')
})

export const AddCredits = () => {
    const { users } = useAppData()

    const onConfirm = (data: AddCreditsFormData) => {
        api.addCredits(data)
            .then(() => {
                const user = users.find((u) => u.id === data.userId)
                notification.success({
                    message: 'Add credits success',
                    description: `${data.amount} hour credits of type "${data.creditType}" were added to ${user?.firstName} ${user?.lastName}`
                })
                resetForm()
                setSubmitting(false)
            })
            .catch((error) => {
                console.error(error)
                notification.error({
                    message: 'Error',
                    description: error?.response?.data?.message || 'Error adding credits. Call Ric, there is a bug!'
                })
            })
    }

    const formik = useFormik<AddCreditsFormData>({
        onSubmit: onConfirm,
        enableReinitialize: true,
        initialValues: {
            userId: '',
            creditType: CreditType.SOLO,
            amount: 0,
            comment: ''
        },
        validationSchema: addCreditsYupSchema
    })
    const { resetForm, setSubmitting } = formik

    return (
        <div>
            <h1 className="text-2xl mb-4 font-bold">Add credits</h1>
            <FormikProvider value={formik}>
                <Form layout="vertical" wrapperCol={{ span: 12 }}>
                    <Form.Item name="userId" label="User">
                        <Select
                            showSearch
                            name="userId"
                            placeholder="Select a user"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children?.join('').toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {users.map((user) => (
                                <Select.Option key={user.id} value={user.id}>
                                    {user.firstName}&nbsp;{user.lastName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="creditType" label="Credit type">
                        <Select name="creditType">
                            <Select.Option value={CreditType.SOLO}>Solo</Select.Option>
                            <Select.Option value={CreditType.DUO}>Duo</Select.Option>
                            <Select.Option value={CreditType.BAND}>Band</Select.Option>
                            <Select.Option value={CreditType.LESSON}>Lesson</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="amount" label="Amount">
                        <InputNumber name="amount" placeholder="0" min={0} max={20} />
                    </Form.Item>
                    <Form.Item name="comment" label="Comment">
                        <Input name="comment" placeholder="Why are you adding credits manually?" />
                    </Form.Item>
                    <SubmitButton>Save</SubmitButton>
                </Form>
            </FormikProvider>
        </div>
    )
}
