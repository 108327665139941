import { ClockCircleOutlined, CommentOutlined, DeleteOutlined, HomeOutlined, TeamOutlined } from "@ant-design/icons";
import { BookingVMWithUser, roomName, standardMomentFormat } from '@kazoo/shared'
import { Button, Popconfirm } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { BookingTypeIndicator } from '../room-usage-indicator/room-usage-indicator'
import { cancelAndRefund } from '../../actions/bookings/cancel-and-refund'

interface EditBookingModalProps {
    bookingVM: BookingVMWithUser
    onCancel: () => void
}

export const EditBookingModal: React.FC<EditBookingModalProps> = ({ bookingVM, onCancel }) => {
    const isBookingInThePast = moment().isAfter(moment(bookingVM.startDate))

    return (
        <Modal
            visible
            width="95vw"
            style={{ maxWidth: 800, maxHeight: '60vhvh' }}
            bodyStyle={{ maxHeight: '60bv', overflowY: 'scroll' }}
            title="Booking options"
            onCancel={onCancel}
            footer={null}
            closable={true}
            centered
        >
            <div className="space-x-2 flex items-center">
                <BookingTypeIndicator bookingType={bookingVM.type} />
                <span>{bookingVM.type}</span>
            </div>
            <div className="space-x-2 flex items-center">
                <HomeOutlined />
                <span>{roomName[bookingVM.room]}</span>
            </div>
            {bookingVM.comment ?
              <div className="space-x-2 flex items-center">
                  <CommentOutlined />
                  <span>{bookingVM.comment}</span>
              </div> : null }
            <div className="space-x-2 flex items-center">
                <ClockCircleOutlined />
                <span>
                    {moment(bookingVM.startDate).format(standardMomentFormat)} to{' '}
                    {moment(bookingVM.endDate).format(standardMomentFormat)}&nbsp;(
                    {moment(bookingVM.endDate).diff(moment(bookingVM.startDate), 'hours')}h)
                </span>
            </div>
            {bookingVM.bandName && (
                <div className="space-x-2 flex items-center">
                    <TeamOutlined />
                    <span>Band "{bookingVM.bandName}"</span>
                </div>
            )}
            {isBookingInThePast && (
                <div className="mt-4 font-semibold">This booking is in the past. No changes can be made to it</div>
            )}
            {!isBookingInThePast && (
                <Popconfirm
                    placement="bottomLeft"
                    title="Are you sure you want to cancel this booking and refund the credits?"
                    onConfirm={()=>cancelAndRefund(bookingVM, onCancel)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button className="mt-4" icon={<DeleteOutlined />}>
                        Cancel and refund credits
                    </Button>
                </Popconfirm>
            )}
            <div className="mt-4">
                <Link to={`users/${bookingVM.user.id}`}>
                    <Button>
                        See user details ({bookingVM.user.firstName} {bookingVM.user.lastName})
                    </Button>
                </Link>
            </div>
        </Modal>
    )
}
