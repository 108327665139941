import { SearchOutlined } from '@ant-design/icons'
import { AdminViewUserVM } from '@kazoo/shared'
import { Button, Input, Space, Table } from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import moment from 'moment'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppData } from '../../hooks/use-app-data'

interface UsersProps extends TableProps<AdminViewUserVM> {
    data?: AdminViewUserVM[]
}

export const Users: React.FC<UsersProps> = ({ data }) => {
    const { users } = useAppData()
    const searchInput = useRef<Input>(null)
    const [{ searchedColumn }, setSearch] = useState({
        searchText: '',
        searchedColumn: ''
    })
    const dataToDisplay = data ?? users

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearch({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex
        })
    }, [])

    const handleReset = useCallback(
        (clearFilters) => {
            clearFilters()
            setSearch({
                searchText: '',
                searchedColumn
            })
        },
        [searchedColumn]
    )

    const getColumnSearchProps = useCallback(
        (dataIndex: string | number) => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilterDropdownVisibleChange: (visible: boolean) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100)
                }
            }
        }),
        [handleSearch, handleReset]
    )

    const columns: ColumnsType<AdminViewUserVM> = useMemo(
        () => [
            {
                title: 'Name',
                dataIndex: 'user',
                key: 'user',
                fixed: 'left',
                width: 150,
                render: (_, adminViewUserVM) => (
                    <span>
                        <Link className="text-blue-500" to={`/users/${adminViewUserVM.id}`}>
                            {adminViewUserVM.firstName}&nbsp;{adminViewUserVM.lastName}
                        </Link>
                    </span>
                ),
                sorter: (a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName),
                onFilter: (value: string | number | boolean, adminViewUserVM: AdminViewUserVM) =>
                    (adminViewUserVM.firstName + adminViewUserVM.lastName).toLowerCase().includes(value.toString()),
                ...getColumnSearchProps('user')
            },
            {
                title: 'Solo H',
                dataIndex: 'soloHours',
                key: 'soloHours',
                render: (soloHours: string) => <span>{soloHours}</span>,
                sorter: (a, b) => a.soloHours - b.soloHours
            },
            {
                title: 'Duo H',
                dataIndex: 'duoHours',
                key: 'duoHours',
                render: (duoHours: string) => <span>{duoHours}</span>,
                sorter: (a, b) => a.duoHours - b.duoHours
            },
            {
                title: 'Band H',
                dataIndex: 'bandHours',
                key: 'bandHours',
                render: (bandHours: string) => <span>{bandHours}</span>,
                sorter: (a, b) => a.bandHours - b.bandHours
            },
            {
                title: 'Lesson H',
                dataIndex: 'lessonHours',
                key: 'lessonHours',
                render: (lessonHours: string) => <span>{lessonHours}</span>,
                sorter: (a, b) => a.lessonHours - b.lessonHours
            },
            {
                title: 'Amount spent',
                dataIndex: 'amountSpent',
                key: 'amountSpent',
                width: 130,
                render: (amountSpent: string) => <span>CHF&nbsp;{amountSpent}</span>,
                sorter: (a, b) => a.amountSpent - b.amountSpent
            },
            {
                title: '# of bands',
                dataIndex: 'numOfBands',
                key: 'numOfBands',
                render: (numOfBands: string) => <span>{numOfBands}</span>,
                sorter: (a, b) => a.numOfBands - b.numOfBands
            },
            {
                title: '# of payments',
                dataIndex: 'numOfPayments',
                key: 'numOfPayments',
                render: (numOfPayments: string) => <span>{numOfPayments}</span>,
                sorter: (a, b) => a.numOfPayments - b.numOfPayments
            },
            {
                title: '# of bookings',
                dataIndex: 'numOfBookings',
                key: 'numOfBookings',
                render: (numOfBookings: string) => <span>{numOfBookings}</span>,
                sorter: (a, b) => a.numOfBookings - b.numOfBookings
            },
            {
                title: '# of cancellations',
                dataIndex: 'numOfCancellations',
                key: 'numOfCancellations',
                render: (numOfCancellations: string) => <span>{numOfCancellations}</span>,
                sorter: (a, b) => a.numOfCancellations - b.numOfCancellations
            },
            {
                title: '# of invited users',
                dataIndex: 'numOfInvitedUsers',
                key: 'numOfInvitedUsers',
                render: (numOfInvitedUsers: string) => <span>{numOfInvitedUsers}</span>,
                sorter: (a, b) => a.numOfInvitedUsers - b.numOfInvitedUsers
            },
            {
                title: 'Last login',
                dataIndex: 'lastLogin',
                key: 'lastLogin',
                width: 150,
                render: (lastLogin?: string) => (lastLogin ? <span>{moment(lastLogin).fromNow()}</span> : '-'),
                sorter: (a, b) => moment(a.lastLogin).valueOf() - moment(b.lastLogin).valueOf()
            },
            {
                title: 'Created',
                dataIndex: 'createdOn',
                key: 'createdOn',
                width: 150,
                render: (createdOn: string) => <span>{moment.utc(createdOn).format('DD/MM/YY HH:mm')}</span>,
                sorter: (a, b) => moment(a.createdOn).valueOf() - moment(b.createdOn).valueOf()
            }
        ],
        [getColumnSearchProps]
    )

    return (
        <div>
            <h1 className="text-2xl mb-4 font-bold">Users</h1>
            <Table rowKey="id" columns={columns} dataSource={dataToDisplay} pagination={false} scroll={{ y: '75vh' }} />
        </div>
    )
}
