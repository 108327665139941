import { getHourRange, getWeekRange } from '@kazoo/shared'
import React, { useState } from 'react'
import { CalendarCols } from '../../components/calendar/calendar-cols'
import { CalendarControls } from '../../components/calendar/calendar-controls'
import { CalendarHeader } from '../../components/calendar/calendar-header'
import { CalendarHours } from '../../components/calendar/calendar-hours'
import { useAppData } from '../../hooks/use-app-data'

export const Calendar: React.FC = () => {
    const { blocks } = useAppData()
    const [weekRange, setWeekRange] = useState<moment.Moment[]>(getWeekRange())
    const hourRange = getHourRange()

    const onWeekRangeChange = (weekRange: moment.Moment[]) => {
        setWeekRange(weekRange)
    }

    return (
        <div>
            <div className="w-[1000px] md:w-full">
                <div className="w-full mx-auto">
                    <CalendarControls weekRange={weekRange} onWeekRangeChange={onWeekRangeChange} />
                </div>
                <div className="mt-2 md:mt-4">
                    <CalendarHeader weekRange={weekRange} />
                </div>
                <div style={{ maxHeight: '80vh' }} className="flex mt-0 md:mt-2 overflow-scroll">
                    <CalendarHours hourRange={hourRange} />
                    <CalendarCols blocks={blocks} hourRange={hourRange} weekRange={weekRange} />
                </div>
            </div>
        </div>
    )
}
