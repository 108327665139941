import {
    AdminViewBandVM,
    AdminViewUserVM,
    AdminWebsocketDataType,
    AdminWebsocketDataVM,
    Blocks,
    BookingVM,
    BookingVMWithUser,
    InvitedUserVM,
    PaymentVM,
    roomName,
    standardMomentFormat
} from '@kazoo/shared'
import { notification } from 'antd'
import moment from 'moment'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { appConfig } from '../app-config'
import { api } from '../services/api'
import { useAuth } from './use-auth'
import { useSocket } from './use-socket'

export interface AppDataProviderValue {
    users: AdminViewUserVM[]
    invitedUsers: InvitedUserVM[]
    bookings: BookingVMWithUser[]
    bands: AdminViewBandVM[]
    payments: PaymentVM[]
    lastRefreshed: moment.Moment
    blocks: Blocks
}

export const AppDataContext = createContext<AppDataProviderValue>({
    users: [],
    bookings: [],
    bands: [],
    payments: [],
    invitedUsers: [],
    blocks: {},
    lastRefreshed: moment()
})

export const AppDataProvider: React.FC = (props: unknown) => {
    const { user } = useAuth()
    const [users, setUsers] = useState<AdminViewUserVM[]>([])
    const [blocks, setBlocks] = useState<Blocks>({})
    const [bookings, setBookings] = useState<BookingVM[]>([])
    const [bands, setBands] = useState<AdminViewBandVM[]>([])
    const [payments, setPayments] = useState<PaymentVM[]>([])
    const [invitedUsers, setInvitedUsers] = useState<InvitedUserVM[]>([])
    const [lastRefreshed, setLastRefreshed] = useState(moment())

    const { socket, connected } = useSocket(`${appConfig.apiUrl}/admin`)

    useEffect(() => {
        if (socket && connected) {
            socket.on('', (response: AdminWebsocketDataVM) => {
                const { user, booking, payment, type, blocks: newBlocks } = response
                setLastRefreshed(moment())
                console.log('socket response', response)
                if (type === AdminWebsocketDataType.NEW_USER && user) {
                    notification.info({
                        message: 'New user',
                        description: `${user.firstName} ${user.lastName} has just registered!`
                    })
                    setUsers((currUsers) => [user, ...currUsers])
                } else if (type === AdminWebsocketDataType.PAYMENT && payment && user) {
                    notification.info({
                        message: 'New payment',
                        description: `${user.firstName} ${user.lastName} has just purchased "${payment.purchasedItemId}" for CHF${payment.amountPaid}!! :-)`
                    })
                    setPayments((currPayments) => [payment, ...currPayments])
                    setUsers((currUsers) => [user, ...currUsers])
                } else if (type === AdminWebsocketDataType.BOOKING && booking && user && newBlocks) {
                    notification.info({
                        message: 'New booking',
                        description: `${user.firstName} ${user.lastName} has just booked the "${
                            roomName[booking.room]
                        }" room for ${booking.type} on ${moment(booking.startDate).format(
                            standardMomentFormat
                        )} - ${moment(booking.endDate).format(standardMomentFormat)} (${moment(booking.endDate).diff(
                            moment(booking.startDate),
                            'h'
                        )}h)`
                    })
                    setBookings((currBookings) => [booking, ...currBookings])
                    setUsers((currUsers) => [user, ...currUsers])
                    setBlocks(newBlocks)
                } else if (type === AdminWebsocketDataType.BOOKING_CANCELLATION && booking && user && newBlocks) {
                    notification.info({
                        message: 'Booking cancelled',
                        description: `${user.firstName} ${user.lastName} has just cancelled the booking on "${
                            roomName[booking.room]
                        }" room for ${booking.type} on ${moment(booking.startDate).format(
                            standardMomentFormat
                        )} - ${moment(booking.endDate).format(standardMomentFormat)} (${moment(booking.endDate).diff(
                            moment(booking.startDate),
                            'h'
                        )}h)`
                    })
                    setBookings((currBookings) => [booking, ...currBookings])
                    setUsers((currUsers) => [user, ...currUsers])
                    setBlocks(newBlocks)
                }
            })
        }
    }, [connected, socket, user.id])

    useEffect(() => {
        api.getInitialData()
            .then((response) => {
                setUsers(response.data.users)
                setBookings(response.data.bookings)
                setPayments(response.data.payments)
                setBands(response.data.bands)
                setInvitedUsers(response.data.invitedUsers)
                setBlocks(response.data.blocks)
                setLastRefreshed(moment())
            })
            .catch((error) => {
                console.error('Load initial data error', error)
                notification.error({
                    message: 'Error loading initial data',
                    description: "Try reloading the page. If it still does not work, get Ric to fix it! THERE'S A BUG!!"
                })
            })
    }, [])

    const appDataValue = useMemo<AppDataProviderValue>(
        () => ({
            users,
            bookings,
            bands,
            payments,
            invitedUsers,
            lastRefreshed,
            blocks
        }),
        [users, bookings, bands, payments, lastRefreshed, invitedUsers, blocks]
    )

    return <AppDataContext.Provider value={appDataValue} {...props} />
}

export const useAppData = (): AppDataProviderValue => useContext<AppDataProviderValue>(AppDataContext)
