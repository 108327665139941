import useDocumentTitle from '@tanem/use-document-title'
import React from 'react'
import { RouteComponentProps } from 'react-router'

export const Home: React.FC<RouteComponentProps> = () => {
    useDocumentTitle('Kazoo - Home')
    // const { result: bookingsResponse, loading } = useAsync(api.bookings.getAllForUser, [])
    // const upcomingBookings = bookingsResponse?.data
    //     ?.filter((booking) => {
    //         return moment(booking.startDate).isAfter(moment())
    //     })
    //     .sort((a, b) => {
    //         return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    //     })

    // if (loading) {
    //     return <Spin />
    // }

    // const upcomingBookingsCard = (
    //     <Card>
    //         <div>
    //             <div className="text-2xl">{t('your_next_bookings', { defaultValue: 'Your next bookings' })}</div>
    //             <Link className="text-sm text-blue-500" to={goToAllBookings()}>
    //                 ({t('manage_all_bookings', { defaultValue: 'Manage all bookings' })})
    //             </Link>
    //         </div>

    //         {upcomingBookings?.length === 0 ? (
    //             <>
    //                 <div className="mt-4 mb-2">
    //                     {t('no_upcoming_bookings', { defaultValue: 'No upcoming bookings' })}
    //                 </div>
    //                 <Link to={goToBookCalendar()}>
    //                     <Button type="primary">{t('book_now')}</Button>
    //                 </Link>
    //             </>
    //         ) : (
    //             upcomingBookings?.map((booking) => <BookingInfo key={booking.id} booking={booking} />)
    //         )}
    //     </Card>
    // )

    return <div className="grid grid-cols-1 md:grid-cols-2">Work in progress</div>
}
