import { ClearOutlined } from '@ant-design/icons'
import {
  BlockDetailsWithStart,
  Blocks,
  BookingType,
  BookingVMWithUser,
  getBookingDisplayTime,
  getDayBlocks,
  halfHourBlocks,
  momentTimezone,
  roomName
} from "@kazoo/shared";
import cn from 'classnames'
import { inRange } from 'lodash'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import React, { useCallback, useMemo, useState } from 'react'
import { useAppData } from '../../hooks/use-app-data'
import { EditBookingModal } from '../edit-booking-modal/edit-booking-modal'
import styles from './styles.module.scss'
import 'moment-timezone';

const moment = extendMoment(Moment)
interface Props {
    blocks: Blocks
    weekRange: moment.Moment[]
    hourRange: moment.Moment[]
}

const now = moment.tz(momentTimezone)

export const CalendarCols: React.FC<Props> = ({ weekRange, hourRange, blocks }) => {
    const [editBookingVM, setEditBookingVM] = useState<BookingVMWithUser | undefined>()
    const { bookings } = useAppData()

    const renderDescription = useCallback(
        (blockDetails: BlockDetailsWithStart) => {
            const { owner, bandDetails, bookingType, id } = blockDetails

            const booking = bookings.find((booking) => booking.id === id)
            const username = `${booking?.user.firstName || ''} ${booking?.user.lastName || ''}`

            let description
            if (bookingType === BookingType.CLEANING) {
                description = <ClearOutlined />
            } else if (owner) {
                if (bandDetails) {
                    description = `${bandDetails.name} - ${username}`
                } else if (bookingType === BookingType.LESSON) {
                    description = `Lesson - ${username}`
                } else if (bookingType === BookingType.DUO) {
                    description = `Duo - ${username}`
                } else if (bookingType === BookingType.SOLO) {
                    description = `Solo - ${username}`
                } else if (bookingType === BookingType.ADMIN_BLOCK) {
                    description = 'Admin'
                }
            }

            return (
                <div className="flex text-xxs">
                    <span className="font-bold">{description}</span>
                </div>
            )
        },
        [bookings]
    )

    const startHourBlockOffset = useMemo(() => hourRange[0].hour() * halfHourBlocks.length, [hourRange])

    return (
        <>
            {editBookingVM && (
                <EditBookingModal bookingVM={editBookingVM} onCancel={() => setEditBookingVM(undefined)} />
            )}
            <div className="grid w-full grid-cols-7">
                {weekRange.map((dayObj, dayIdx) => (
                    <div key={`${dayObj.valueOf()}-${dayIdx}`} className="relative flex flex-col w-full">
                        {hourRange.map((hourObj, hourIdx) => (
                            <div key={`${hourObj.valueOf()} - ${hourIdx}`} className="flex flex-col w-full h-[40px]">
                                {halfHourBlocks.map((_, halfHourIdx) => {
                                    return (
                                        <div
                                            key={halfHourIdx}
                                            className={cn('h-[20px] bg-white border border-grey-400 relative', {
                                                'border-r-0': dayIdx !== 6,
                                                'border-t-0': hourIdx !== 0 || (hourIdx === 0 && halfHourIdx === 1),
                                                [styles.nowIndicator]:
                                                    now.day() === dayObj.day() &&
                                                    inRange(hourObj.diff(now, 'minutes') + halfHourIdx * 30, -29, 0)
                                            })}
                                        />
                                    )
                                })}
                            </div>
                        ))}
                        {getDayBlocks(blocks, dayObj).map((blockDetails, idx, self) => {
                            const { startBlock, duration, bookingType, room, id } = blockDetails

                            const width = 92 / Object.keys(roomName).length
                            const left = width * (room - 1) + room * 2

                            return (
                                <div
                                    key={`${startBlock}-${idx}`}
                                    className={cn('absolute flex', {
                                        'z-0': bookingType === BookingType.CLEANING
                                    })}
                                    style={{
                                        top: `calc(20px * ${startBlock - startHourBlockOffset})`,
                                        height: `calc(20px * ${duration})`,
                                        width: `${width}%`,
                                        left: `${left}%`,
                                        paddingBottom: 2
                                    }}
                                >
                                    <div className="relative w-full">
                                        <div className="h-full">
                                            <button
                                                onClick={() =>
                                                    setEditBookingVM(bookings.find((booking) => booking.id === id))
                                                }
                                                disabled={bookingType === BookingType.CLEANING}
                                                style={{ padding: 2 }}
                                                className={cn(
                                                    'group z-2 relative h-full w-full border rounded mx-auto text-left leading-none flex flex-col text-white overflow-hidden',
                                                    {
                                                        'border-blue-600 blue-500-stripes':
                                                            bookingType === BookingType.CLEANING,
                                                        'bg-black': bookingType === BookingType.SOLO,
                                                        'bg-pink-400': bookingType === BookingType.DUO,
                                                        'bg-yellow-500': bookingType === BookingType.LESSON,
                                                        'bg-blue-500': bookingType === BookingType.BAND,
                                                        'bg-red-500': bookingType === BookingType.ADMIN_BLOCK,
                                                        'border-red-500 red-500-stripes':
                                                            bookingType === BookingType.ADMIN_BLOCK,
                                                        'cursor-not-allowed': bookingType === BookingType.CLEANING
                                                    }
                                                )}
                                            >
                                                {bookingType !== BookingType.CLEANING && (
                                                    <div className="tracking-tighter text-xxs">
                                                        {getBookingDisplayTime(startBlock, duration).label}
                                                    </div>
                                                )}
                                                {bookingType !== BookingType.CLEANING && (
                                                    <div
                                                        style={{ marginTop: 2 }}
                                                        className="flex items-center tracking-tighter text-xxs"
                                                    >
                                                        <span className="font-bold text-xxs">{roomName[room]}</span>
                                                    </div>
                                                )}
                                                {renderDescription(blockDetails)}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ))}
            </div>
        </>
    )
}
