import { SearchOutlined } from '@ant-design/icons'
import { PaymentVM } from '@kazoo/shared'
import { Button, Input, Space, Table } from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import moment from 'moment'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppData } from '../../hooks/use-app-data'

interface PaymentsData extends TableProps<PaymentVM> {
    data?: PaymentVM[]
    hideUserColumn?: boolean
}

export const Payments: React.FC<PaymentsData> = ({ data, hideUserColumn, ...tableProps }) => {
    const { payments } = useAppData()
    const dataToDisplay = data ?? payments

    const searchInput = useRef<Input>(null)
    const [{ searchedColumn }, setSearch] = useState({
        searchText: '',
        searchedColumn: ''
    })

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearch({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex
        })
    }, [])

    const handleReset = useCallback(
        (clearFilters) => {
            clearFilters()
            setSearch({
                searchText: '',
                searchedColumn
            })
        },
        [searchedColumn]
    )

    const getColumnSearchProps = useCallback(
        (dataIndex: string | number) => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilterDropdownVisibleChange: (visible: boolean) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100)
                }
            }
        }),
        [handleReset, handleSearch]
    )

    const columns: ColumnsType<PaymentVM> = useMemo(
        () => [
            {
                title: 'Paid by',
                dataIndex: 'user',
                key: 'user',
                render: (_, paymentVM) => (
                    <span>
                        <Link className="text-blue-500" to={`/users/${paymentVM.user.id}`}>
                            {paymentVM.user.firstName}&nbsp;{paymentVM.user.lastName}
                        </Link>
                    </span>
                ),
                sorter: (a, b) =>
                    (a.user.firstName + a.user.lastName).localeCompare(b.user.firstName + b.user.lastName),
                onFilter: (value: string | number | boolean, paymentVM) =>
                    (paymentVM.user.firstName + paymentVM.user.lastName).toLowerCase().includes(value.toString()),
                ...getColumnSearchProps('user')
            },
            {
                title: 'Pack',
                dataIndex: 'purchasedItemId',
                key: 'purchasedItemId',
                render: (purchasedItemId: string) => <span>{purchasedItemId}</span>,
                sorter: (a, b) => a.purchasedItemId.localeCompare(b.purchasedItemId),
                onFilter: (value: string | number | boolean, paymentVM) =>
                    paymentVM.purchasedItemId.toLowerCase().includes(value.toString()),
                ...getColumnSearchProps('purchasedItemId')
            },
            {
                title: 'Amount',
                dataIndex: 'amountPaid',
                key: 'amountPaid',
                render: (amountPaid: number) => <span>CHF&nbsp;{amountPaid}</span>,
                sorter: (a, b) => a.amountPaid - b.amountPaid
            },
            {
                title: 'Payment method',
                dataIndex: 'paymentMethod',
                key: 'paymentMethod',
                render: (_, paymentVM) => <span>{paymentVM.paymentMethod ?? '-'}</span>,
                sorter: (a, b) => (a.paymentMethod ?? '').localeCompare(b.paymentMethod ?? '')
            },
            {
                title: 'Date',
                dataIndex: 'createdOn',
                key: 'createdOn',
                render: (createdOn: string) => <span>{moment.utc(createdOn).format('DD/MM/YY HH:mm')}</span>,
                sorter: (a, b) => moment(a.createdOn).valueOf() - moment(b.createdOn).valueOf()
            }
        ],
        [getColumnSearchProps]
    )

    return (
        <div>
            <h1 className="text-2xl mb-4 font-bold">Payments</h1>
            <Table
                dataSource={dataToDisplay}
                pagination={false}
                rowKey="id"
                columns={columns.filter((column) => {
                    if (column.key === 'user' && hideUserColumn) {
                        return false
                    }
                    return true
                })}
                scroll={{ y: '60vh' }}
                {...tableProps}
            />
        </div>
    )
}
