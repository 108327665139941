import useDocumentTitle from '@tanem/use-document-title'
import { setup as setupBreakpoints } from '@w11r/use-breakpoint'
import 'antd/dist/antd.css'
import 'moment/locale/en-gb'
import 'moment/locale/fr'
import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { appConfig } from './app-config'
import { Layout } from './components/layout/layout'
import { AppDataProvider } from './hooks/use-app-data'
import { AuthDataProvider, useAuth } from './hooks/use-auth'
import { AddCredits } from './pages/add-credits/add-credits'
import { Band } from './pages/band/band'
import { Bands } from './pages/bands/bands'
import { BlockStudios } from './pages/block-studios/block-studios'
import { Bookings } from './pages/bookings/bookings'
import { Calendar } from './pages/calendar/calendar'
import { Home } from './pages/home/home'
import { InvitedUsers } from './pages/invited-users/invited-users'
import { Login } from './pages/login/login'
import { Payments } from './pages/payments/payments'
import { User } from './pages/user/user'
import { Users } from './pages/users/users'
import './styles/index.css'
import './styles/tailwind.css'

setupBreakpoints({
    breakpoints: {
        // same as tailwind
        mobile: [0, 767],
        desktop: [768, 1023],
        lg: [1024, 1279],
        xl: [1280, 1535],
        xl2: [1536, 10000]
    }
})

const PrivateRoute = (props: RouteProps) => {
    const { component, ...otherProps } = props
    const { user } = useAuth()
    useDocumentTitle('Admin - Kazoo')

    if (!user.email) {
        return (
            <Redirect
                to={{
                    pathname: appConfig.pathnames.login
                }}
            />
        )
    }

    return <Route component={component} {...otherProps} />
}

ReactDOM.render(
    <BrowserRouter>
        <Suspense fallback={<div className="fixed inset-0 bg-black" />}>
            <AuthDataProvider>
                <main className="absolute inset-0 bg-black">
                    <Switch>
                        <Route exact path={appConfig.pathnames.login} component={Login} />
                        <Layout>
                            <PrivateRoute
                                path=""
                                component={() => (
                                    <AppDataProvider>
                                        <Route exact path={appConfig.pathnames.home} component={Home} />
                                        <Route exact path={appConfig.pathnames.calendar} component={Calendar} />
                                        <Route exact path={appConfig.pathnames.blockStudios} component={BlockStudios} />
                                        <Route exact path={appConfig.pathnames.bookings} component={Bookings} />
                                        <Route exact path={appConfig.pathnames.payments} component={Payments} />
                                        <Route exact path={appConfig.pathnames.addCredits} component={AddCredits} />
                                        <Route exact path={`${appConfig.pathnames.users}/:id`} component={User} />
                                        <Route exact path={`${appConfig.pathnames.bands}/:id`} component={Band} />
                                        <Route exact path={appConfig.pathnames.users} component={Users} />
                                        <Route exact path={appConfig.pathnames.bands} component={Bands} />
                                        <Route exact path={appConfig.pathnames.invitedUsers} component={InvitedUsers} />
                                    </AppDataProvider>
                                )}
                            />
                        </Layout>
                    </Switch>
                </main>
            </AuthDataProvider>
        </Suspense>
    </BrowserRouter>,
    document.getElementById('root')
)
