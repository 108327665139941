import { Layout as AntdLayout, notification } from 'antd'
import React, { useEffect } from 'react'
import { Header } from '../header/header'
import { DesktopMenu } from './desktop-menu'
import './layout.scss'

export const Layout: React.FC = ({ children }) => {
    useEffect(() => {
        notification.config({
            top: 80,
            duration: 10
        })
    })

    return (
        <AntdLayout style={{ height: '100vh', flexDirection: 'row' }}>
            <DesktopMenu />
            <AntdLayout className="items-center md:w-100vh overflow-auto md:overflow-hidden">
                <Header />
                <AntdLayout.Content className="w-full px-4 py-4 pb-8 md:py-8 md:overflow-x-hidden overflow-y-auto overflow-x-auto">
                    {children}
                </AntdLayout.Content>
            </AntdLayout>
        </AntdLayout>
    )
}
