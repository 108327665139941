import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { LoginUserDto } from '@kazoo/shared'
import useDocumentTitle from '@tanem/use-document-title'
import { Modal } from 'antd'
import { FormikProvider, useFormik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useAuth } from '../../hooks/use-auth'
import KazooLogo from '../../images/logo-white.png'
import { LoginFormData } from '../../types'

const LoginSchema = yup.object().shape({
    email: yup.string().required('Enter email').email('Invalid email'),
    password: yup.string().required('Enter password')
})

export const Login: React.FC = () => {
    useDocumentTitle('Kazoo - Login')
    const [, setError] = useState(null)
    const { login } = useAuth()

    const onSubmit = async (data: LoginFormData) => {
        try {
            await login(data)
        } catch (error) {
            // TODO: do something with this error...
            setError(error)
        }
    }

    const formik = useFormik<LoginUserDto>({
        onSubmit,
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: LoginSchema
    })

    return (
        <FormikProvider value={formik}>
            <>
                <img className="w-32 mx-auto mt-4 lg:mt-8 lg:w-64" src={KazooLogo} alt="kazoo logo" />
                <Modal footer={null} mask={false} centered={true} closable={false} maskClosable={false} visible={true}>
                    <Form className="mt-4">
                        <Form.Item name="email" className="mb-4">
                            <Input
                                name="email"
                                data-test="emailInput"
                                prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Email"
                                type="email"
                            />
                        </Form.Item>
                        <Form.Item name="password" className="mb-4">
                            <Input
                                name="password"
                                data-test="passwordInput"
                                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <div className="flex flex-col items-center">
                            <SubmitButton data-test="loginSubmit" className="w-4/6 lg:w-2/5">
                                Login
                            </SubmitButton>
                        </div>
                    </Form>
                </Modal>
            </>
        </FormikProvider>
    )
}
