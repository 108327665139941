import {
    CalendarOutlined,
    CreditCardOutlined,
    DollarOutlined,
    ExclamationOutlined,
    HistoryOutlined,
    HomeOutlined,
    SoundOutlined,
    TeamOutlined
} from '@ant-design/icons'
import { Menu } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { appConfig } from '../../app-config'
import AppLogo from '../../images/logo-white.png'

export const DesktopMenu = () => {
    const { pathname } = useLocation()

    return (
        <>
            <Sider collapsible width={240}>
                <Menu theme="dark" mode="inline" selectedKeys={[pathname]}>
                    <div className="">
                        <img src={AppLogo} alt="kazoo logo" className="w-3/4 mx-auto my-8" />
                    </div>
                    <Menu.Item className="flex items-center" key={appConfig.pathnames.home}>
                        <HomeOutlined />
                        <span>Home</span>
                        <Link to={appConfig.pathnames.home} />
                    </Menu.Item>
                    <Menu.Item className="flex items-center" key={appConfig.pathnames.calendar}>
                        <CalendarOutlined />
                        <span>Calendar</span>
                        <Link to={appConfig.pathnames.calendar} />
                    </Menu.Item>
                    <Menu.Item className="flex items-center" key={appConfig.pathnames.blockStudios}>
                        <ExclamationOutlined />
                        <span>Block studios</span>
                        <Link to={appConfig.pathnames.blockStudios} />
                    </Menu.Item>
                    <Menu.Item className="flex items-center" key={appConfig.pathnames.bookings}>
                        <HistoryOutlined />
                        <span>Bookings</span>
                        <Link to={appConfig.pathnames.bookings} />
                    </Menu.Item>
                    <Menu.Item className="flex items-center" key={appConfig.pathnames.payments}>
                        <DollarOutlined />
                        <span>Payments</span>
                        <Link to={appConfig.pathnames.payments} />
                    </Menu.Item>
                    <Menu.Item className="flex items-center" key={appConfig.pathnames.addCredits}>
                        <CreditCardOutlined />
                        <span>Add credits</span>
                        <Link to={appConfig.pathnames.addCredits} />
                    </Menu.Item>
                    <Menu.Item className="flex items-center" key={appConfig.pathnames.users}>
                        <TeamOutlined />
                        <span>Users</span>
                        <Link to={appConfig.pathnames.users} />
                    </Menu.Item>
                    <Menu.Item className="flex items-center" key={appConfig.pathnames.bands}>
                        <SoundOutlined />
                        <span>Bands</span>
                        <Link to={appConfig.pathnames.bands} />
                    </Menu.Item>
                    <Menu.Item className="flex items-center" key={appConfig.pathnames.invitedUsers}>
                        <TeamOutlined />
                        <span>Invited users</span>
                        <Link to={appConfig.pathnames.invitedUsers} />
                    </Menu.Item>
                </Menu>
            </Sider>
        </>
    )
}
