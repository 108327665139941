import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Room, roomName } from '@kazoo/shared'
import { BlockStudiosDto } from '@kazoo/shared/src/dto/block-studios.dto'
import { Button, notification } from 'antd'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { Checkbox, DatePicker, Form, Input, SubmitButton } from "formik-antd";
import { Moment } from 'moment'
import React, { useState } from 'react'
import * as yup from 'yup'
import { api } from '../../services/api'

interface BlockCreditsFormData {
    studios: Room[]
    dates: [Moment, Moment][]
    comment: string
}

const blockCreditsYupSchema = yup.object<BlockCreditsFormData>().shape({
    studios: yup.array().required('Select at least 1 room').min(1, 'Select at least 1 room'),
    dates: yup
        .array(yup.array(yup.object()).min(2).max(2).required('Choose a date'))
        .min(1, 'Choose at least one date')
        .required('Choose a date'),
    comment: yup.string().required('Add a comment / description')
})

export const BlockStudios: React.FC = () => {
    const [loading, setLoading] = useState(false)

    const onConfirm = (data: BlockCreditsFormData) => {
        const formattedData: BlockStudiosDto = {
            studios: data.studios,
            dates: data.dates.map((dateTuple) => {
                return [dateTuple[0].toJSON(), dateTuple[1].toJSON()]
            }),
            comment: data.comment
            // TODO if I want to simply blocks per example every monday or something
        }

        console.log('#Test front', formattedData)
        setLoading(true)
        api.blockStudios(formattedData)
            .then((response) => {
                if (!response.data.ok) {
                    throw new Error(response.data.message)
                }

                notification.success({
                    message: 'Block studios success'
                })
                // resetForm()
                setSubmitting(false)
            })
            .catch((error) => {
                console.error(error)
                notification.error({
                    message: 'Error',
                    description:
                        error?.response?.data?.message ||
                        error?.message ||
                        'Error blocking studios. Call Ric, there is a bug!'
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const formik = useFormik<BlockCreditsFormData>({
        onSubmit: onConfirm,
        enableReinitialize: true,
        initialValues: {
            studios: [],
            dates: [[] as any],
            comment: ''
        },
        validationSchema: blockCreditsYupSchema
    })
    const { setSubmitting, values } = formik

    const studioOptions = [
        { label: roomName[Room.ONE], value: Room.ONE },
        { label: roomName[Room.TWO], value: Room.TWO },
        { label: roomName[Room.THREE], value: Room.THREE },
        { label: roomName[Room.FOUR], value: Room.FOUR }
    ]

    return (
        <div>
            <h1 className="text-2xl mb-4 font-bold">Block studios</h1>
            <FormikProvider value={formik}>
                <Form layout="vertical" wrapperCol={{ span: 12 }}>
                    <Form.Item name="studios" label="Studios">
                        <Checkbox.Group name="studios" options={studioOptions} />
                    </Form.Item>
                    <FieldArray
                        name="dates"
                        render={(arrayHelpers) => (
                            <div>
                                {new Array(values.dates.length).fill(0).map((_, idx) => (
                                    <div key={idx} className="items-center space-x-2">
                                        <Form.Item
                                            className="mb-2 mt-1"
                                            name={`dates.${idx}`}
                                            label={idx === 0 ? 'Dates' : ''}
                                        >
                                            <DatePicker.RangePicker
                                                name={`dates.${idx}`}
                                                format="DD-MM-YYYY HH:mm"
                                                showTime={{ format: 'HH:mm' }}
                                            />
                                        </Form.Item>
                                        {idx !== 0 && (
                                            <Button
                                                type="dashed"
                                                shape="circle"
                                                size="small"
                                                onClick={() => arrayHelpers.remove(idx)}
                                                className="mr-1 items-center justify-center"
                                                icon={<CloseOutlined />}
                                            />
                                        )}
                                    </div>
                                ))}
                                <Button
                                    type="dashed"
                                    onClick={() => arrayHelpers.push([])}
                                    className="mt-2 mb-4 flex items-center justify-center"
                                    icon={<PlusOutlined />}
                                >
                                    Add date
                                </Button>
                            </div>
                        )}
                    />
                    <Form.Item name="comment" label="Comment" className="mt-2">
                        <Input name="comment" placeholder="Why are you blocking manually?" />
                    </Form.Item>
                    <SubmitButton loading={loading} className="mt-10">
                        Save
                    </SubmitButton>
                </Form>
            </FormikProvider>
        </div>
    )
}
