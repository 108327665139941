import { BookingType } from '@kazoo/shared'
import cn from 'classnames'
import React from 'react'

interface RoomUsageIndicatorProps {
    bookingType: BookingType
}

export const BookingTypeIndicator: React.FC<RoomUsageIndicatorProps> = ({ bookingType }) => {
    return (
        <div
            className={cn('h-3 w-3 rounded', {
                'bg-black': bookingType === BookingType.SOLO,
                'bg-pink-400': bookingType === BookingType.DUO,
                'bg-yellow-500': bookingType === BookingType.LESSON,
                'bg-blue-500': bookingType === BookingType.BAND,
                'bg-red-500': bookingType === BookingType.ADMIN_BLOCK
            })}
        />
    )
}
