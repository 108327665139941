import * as moment from 'moment'
import { extendMoment } from 'moment-range'
import React from 'react'
extendMoment(moment)

interface CalendarHoursProps {
    hourRange: moment.Moment[]
}

export const CalendarHours: React.FC<CalendarHoursProps> = ({ hourRange }) => (
    <div className="flex flex-col">
        {hourRange.map((hourObj) => (
            <span key={hourObj.hour()} className="flex-shrink-0 w-10 h-10 text-xs text-center text">
                {hourObj.format('HH')}h
            </span>
        ))}
    </div>
)
