import { AddCreditsDto, AdminDataVM, BlockStudiosDto, BookingVM, LoginStatus, SimpleResponse } from '@kazoo/shared'
import axios from 'axios'
import { appConfig } from '../app-config'
import { LoginFormData } from '../types'
import { JwtStore } from './jwt-store'

export const api = {
    user: {
        loginWithJwtAdmin: (jwt: string) =>
            axios.post<LoginStatus>(`${appConfig.apiUrl}/auth/jwt-login-admin`, null, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            }),
        loginAdmin: (data: LoginFormData) => axios.post<LoginStatus>(`${appConfig.apiUrl}/auth/login-admin`, data)
    },
    getInitialData: () =>
        axios.get<AdminDataVM>(`${appConfig.apiUrl}/admin/initial-data`, {
            headers: JwtStore.getAuthHeaders()
        }),
    addCredits: (data: AddCreditsDto) =>
        axios.post<SimpleResponse>(`${appConfig.apiUrl}/admin/add-credits`, data, {
            headers: JwtStore.getAuthHeaders()
        }),
    blockStudios: (data: BlockStudiosDto) =>
        axios.post<SimpleResponse>(`${appConfig.apiUrl}/admin/block-studios`, data, {
            headers: JwtStore.getAuthHeaders()
        }),
    cancelBooking: (data: BookingVM) =>
        axios.post<SimpleResponse>(`${appConfig.apiUrl}/admin/cancel-booking`, data, {
            headers: JwtStore.getAuthHeaders()
        })
}
