import { DownOutlined } from '@ant-design/icons'
import { standardMomentFormat } from '@kazoo/shared'
import { Avatar, Dropdown, Menu } from 'antd'
import moment from 'moment'
import React from 'react'
import { useAppData } from '../../hooks/use-app-data'
import { useAuth } from '../../hooks/use-auth'

export const Header: React.FC = () => {
    const { user, logout } = useAuth()
    const { lastRefreshed } = useAppData()

    const menu = (
        <Menu>
            <Menu.Item onClick={logout} key="3">
                Logout
            </Menu.Item>
        </Menu>
    )

    return (
        <div className="w-full h-16 bg-white shadow">
            <div className="h-full max-w-6xl px-4 mx-auto">
                <div className="flex h-full">
                    <div className="grid w-full grid-cols-12">
                        <div className="flex items-center col-span-1 md:col-span-4"></div>
                        <div className="flex items-center col-span-10 md:col-span-4">
                            <div className="flex flex-col">
                                <p className="text-xs font-semibold caps-small text-center">LAST REFRESHED</p>
                                <div className="mt-1">
                                    <span className="font-semibold leading-none text-center text-xs">
                                        {moment(lastRefreshed).format(standardMomentFormat)}&nbsp;(
                                        {moment(lastRefreshed).fromNow()})
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center ml-auto col-span-1 md:col-span-4">
                            <Dropdown overlay={menu} trigger={['click']}>
                                <button className="outline-none">
                                    <Avatar size="small" className="mr-2">
                                        {user.firstName[0]}
                                    </Avatar>
                                    <span className="hidden md:inline-block">
                                        {user.firstName}&nbsp;{user.lastName}&nbsp;
                                    </span>
                                    <DownOutlined />
                                </button>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
