import { api } from "../../services/api";
import { notification } from "antd";
import { BookingVMWithUser } from "@kazoo/shared";

export const cancelAndRefund = (bookingVM: BookingVMWithUser, onCancel?: () => void) => {
    api.cancelBooking(bookingVM)
        .then((response) => {
            if (response.data.ok) {
                notification.success({
                    message: 'Booking cancelled successfully',
                    description: 'The credits have been refunded directly to the customer'
                })
                onCancel && onCancel()
            } else {
                throw new Error(response.data.message)
            }
        })
        .catch((error) => {
            notification.error({
                message: 'Booking could not be cancelled',
                description: error?.response?.data?.message
            })
        })
}