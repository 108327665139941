import { Lang, LoginStatus, UserVM } from '@kazoo/shared'
import { notification } from 'antd'
import moment from 'moment'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { appConfig } from '../app-config'
import { api } from '../services/api'
import { JwtStore } from '../services/jwt-store'
import { LoginFormData } from '../types'
import { emptyUserData } from '../utils/default-objects'
import { goToLogin } from '../utils/routing'

export interface AuthDataProviderValue {
    user: UserVM
    logout: () => void
    login: (data: LoginFormData) => Promise<void>
}

export const AuthDataContext = createContext<AuthDataProviderValue>({
    user: emptyUserData,
    login: () => Promise.resolve(),
    logout: () => undefined
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AuthDataProvider: React.FC = (props?: unknown) => {
    const [user, setUser] = useState(emptyUserData)
    const history = useHistory()
    const location = useLocation()

    const onLogin = useCallback(async (data: LoginStatus) => {
        setUser(data.user)
        moment.locale(Lang.EN)
        await JwtStore.persistJwt(data.jwt)
    }, [])

    /*
        Initial attempt to login if there is a JWT in localStorage
        eg. user refreshed the page
    */
    useEffect(() => {
        console.info(`Running app version: ${appConfig.appVersion}`)

        async function checkForJwtAndLogin() {
            try {
                const localStorageJwt = await JwtStore.getLocalStorageJwt()
                // if email is in memory, it means the user has logged in successfully
                if (!localStorageJwt || user.email) {
                    return
                }
                const userDataResponse = await api.user.loginWithJwtAdmin(localStorageJwt)
                await onLogin(userDataResponse.data)
                const from = location.pathname || appConfig.pathnames.home
                history.replace(from)
            } catch (error) {
                console.warn('Error in auth provider', error)
                await JwtStore.clearJwt()
            }
        }

        void checkForJwtAndLogin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const logout = useCallback(async () => {
        setUser(emptyUserData)
        await JwtStore.clearJwt()
        history.push(goToLogin())
    }, [history])

    const login = useCallback(
        async (data: LoginFormData): Promise<void> => {
            try {
                const response = await api.user.loginAdmin(data)
                await onLogin(response.data)
                history.replace(appConfig.pathnames.home)
            } catch (error) {
                void notification.error({
                    message:
                        error?.response?.data?.message ||
                        'There was an error logging you in. The technical team has been notified of this problem. Please try to login again.'
                })
            }
        },
        [history, onLogin]
    )

    const authDataValue = useMemo<AuthDataProviderValue>(
        () => ({
            user,
            login,
            logout
        }),
        [user, login, logout]
    )

    return <AuthDataContext.Provider value={authDataValue} {...props} />
}

export const useAuth = (): AuthDataProviderValue => useContext<AuthDataProviderValue>(AuthDataContext)
