import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { getWeekRange, momentTimezone } from "@kazoo/shared";
import { Button } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import React, { useCallback } from 'react'

const moment = extendMoment(Moment as any)

interface CalendarControlsProps {
    weekRange: moment.Moment[]
    onWeekRangeChange: (weekRange: moment.Moment[]) => void
}

const today = moment.tz(momentTimezone)

export const CalendarControls: React.FC<CalendarControlsProps> = ({ weekRange, onWeekRangeChange }) => {
    const firstDay = weekRange[0]
    const lastDay = weekRange[weekRange.length - 1]
    const currWeekOffset = firstDay.diff(today.clone().startOf('week'), 'weeks')

    const handleToday = useCallback(() => {
        onWeekRangeChange(getWeekRange())
    }, [onWeekRangeChange])

    const handlePrevWeek = () => {
        onWeekRangeChange(getWeekRange(currWeekOffset - 1))
    }
    const handleNextWeek = () => {
        onWeekRangeChange(getWeekRange(currWeekOffset + 1))
    }

    return (
        <div className="flex items-center">
            <Button size="middle" className="text-base" onClick={handleToday}>
                This week
            </Button>
            <ButtonGroup className="pl-2">
                <Button
                    size="middle"
                    onClick={handlePrevWeek}
                    className="flex items-center justify-center"
                    icon={<ArrowLeftOutlined />}
                />
                <Button
                    size="middle"
                    onClick={handleNextWeek}
                    className="flex items-center justify-center"
                    icon={<ArrowRightOutlined />}
                />
            </ButtonGroup>

            <div className="ml-2">
                <span>
                    {firstDay.format('LL')} - {lastDay.format('LL')}
                </span>
            </div>
        </div>
    )
}
