export const appConfig = {
    appVersion: process.env.REACT_APP_VERSION || '',
    apiUrl: process.env.REACT_APP_API_URL || '',
    pathnames: {
        home: '/',
        login: '/login',
        bookings: '/bookings',
        payments: '/payments',
        addCredits: '/add-credits',
        users: '/users',
        bands: '/bands',
        calendar: '/calendar',
        blockStudios: '/block-studios',
        invitedUsers: '/invited-users'
    }
}
