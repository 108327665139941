import { AdminViewBandVM, standardMomentFormat } from '@kazoo/shared'
import { Tag } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { useAppData } from '../../hooks/use-app-data'
import { Bookings } from '../bookings/bookings'
import { InvitedUsers } from '../invited-users/invited-users'
import { Users } from '../users/users'

interface BandRouteParams {
    id: string
}

export const Band: React.FunctionComponent<RouteComponentProps<BandRouteParams>> = (props) => {
    const { users, bookings, invitedUsers, bands } = useAppData()
    const bandId = props.match.params.id
    const band = bands.find((band) => band.id === bandId)

    if (!band) {
        return <div>No band found</div>
    }

    const bandRegisteredMembers = users.filter((user) => band.registeredUserIds.includes(user.id))
    const bandInvitedMembers = invitedUsers.filter((user) => band.invitedUserIds.includes(user.id))
    const bandBookings = bookings.filter((booking) => booking.bandId === bandId)

    const columns: ColumnsType<AdminViewBandVM> = [
        {
            title: 'Created by',
            dataIndex: 'ownerName',
            key: 'ownerName',
            render: (ownerName, adminViewBandVM) => (
                <span>
                    <Link className="text-blue-500" to={`/users/${adminViewBandVM.ownerId}`}>
                        {ownerName}
                    </Link>
                </span>
            )
        },
        {
            title: '# of bookings',
            dataIndex: 'numOfBookings',
            key: 'numOfBookings',
            render: (numOfBookings: string) => <span>{numOfBookings}</span>
        },
        {
            title: '# of cancellations',
            dataIndex: 'numOfCancellations',
            key: 'numOfCancellations',
            render: (numOfCancellations: string) => <span>{numOfCancellations}</span>
        },
        {
            title: '# of registered members',
            dataIndex: 'numOfRegisteredUsers',
            key: 'numOfRegisteredUsers',
            render: (numOfRegisteredUsers: string) => <span>{numOfRegisteredUsers}</span>
        },
        {
            title: '# of invited members',
            dataIndex: 'numOfInvitedUsers',
            key: 'numOfInvitedUsers',
            render: (numOfInvitedUsers: string) => <span>{numOfInvitedUsers}</span>
        },
        {
            title: 'Deleted',
            dataIndex: 'archived',
            key: 'archived',
            render: (_, bookingVM) => (bookingVM.archived ? <Tag color="orange">Deleted</Tag> : '-')
        },
        {
            title: 'Created',
            dataIndex: 'createdOn',
            key: 'createdOn',
            width: 150,
            render: (createdOn: string) => <span>{moment.utc(createdOn).format(standardMomentFormat)}</span>
        },
        {
            title: 'Updated',
            dataIndex: 'updatedOn',
            key: 'updatedOn',
            width: 150,
            render: (updatedOn: string) => <span>{moment.utc(updatedOn).format(standardMomentFormat)}</span>,
            sorter: (a, b) => moment(a.updatedOn).valueOf() - moment(b.updatedOn).valueOf()
        }
    ]

    return (
        <div>
            <h1 className="text-2xl mb-4 font-bold">Band "{band.name}"</h1>

            <Table rowKey="id" columns={columns} dataSource={[band]} pagination={false} scroll={{ x: true }} />

            <div className="mt-4">
                <Bookings data={bandBookings} hideUserColumn scroll={{ y: 300 }} />
            </div>
            <div className="mt-4">
                <Users data={bandRegisteredMembers} scroll={{ y: 300, x: true }} />
            </div>
            <div className="mt-4">
                <InvitedUsers data={bandInvitedMembers} hideUserColumn scroll={{ y: 300 }} />
            </div>
        </div>
    )
}
