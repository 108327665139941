import { AdminViewUserVM } from '@kazoo/shared'
import Table, { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useAppData } from '../../hooks/use-app-data'
import { Bands } from '../bands/bands'
import { Bookings } from '../bookings/bookings'
import { InvitedUsers } from '../invited-users/invited-users'
import { Payments } from '../payments/payments'

interface UserRouteParams {
    id: string
}

export const User: React.FunctionComponent<RouteComponentProps<UserRouteParams>> = (props) => {
    const { users, bookings, invitedUsers, payments, bands } = useAppData()
    const userId = props.match.params.id
    const user = users.find((user) => user.id === userId)

    if (!user) {
        return <div>No user found</div>
    }

    const userBands = bands.filter((band) => band.ownerId === userId)
    const userPayments = payments.filter((payment) => payment.user.id === userId)
    const userBookings = bookings.filter((booking) => booking.user.id === userId)
    const userInvitedUsers = invitedUsers.filter((invitedUser) => invitedUser.invitedByUserId === userId)

    const columns: ColumnsType<AdminViewUserVM> = [
        {
            title: 'Solo H',
            dataIndex: 'soloHours',
            key: 'soloHours',
            render: (soloHours: string) => <span>{soloHours}</span>,
            sorter: (a, b) => a.soloHours - b.soloHours
        },
        {
            title: 'Duo H',
            dataIndex: 'duoHours',
            key: 'duoHours',
            render: (duoHours: string) => <span>{duoHours}</span>,
            sorter: (a, b) => a.duoHours - b.duoHours
        },
        {
            title: 'Band H',
            dataIndex: 'bandHours',
            key: 'bandHours',
            render: (bandHours: string) => <span>{bandHours}</span>,
            sorter: (a, b) => a.bandHours - b.bandHours
        },
        {
            title: 'Lesson H',
            dataIndex: 'lessonHours',
            key: 'lessonHours',
            render: (lessonHours: string) => <span>{lessonHours}</span>,
            sorter: (a, b) => a.lessonHours - b.lessonHours
        },
        {
            title: 'Amount spent',
            dataIndex: 'amountSpent',
            key: 'amountSpent',
            width: 130,
            render: (amountSpent: string) => <span>CHF&nbsp;{amountSpent}</span>,
            sorter: (a, b) => a.amountSpent - b.amountSpent
        },
        {
            title: '# of bands',
            dataIndex: 'numOfBands',
            key: 'numOfBands',
            render: (numOfBands: string) => <span>{numOfBands}</span>,
            sorter: (a, b) => a.numOfBands - b.numOfBands
        },
        {
            title: '# of payments',
            dataIndex: 'numOfPayments',
            key: 'numOfPayments',
            render: (numOfPayments: string) => <span>{numOfPayments}</span>,
            sorter: (a, b) => a.numOfPayments - b.numOfPayments
        },
        {
            title: '# of bookings',
            dataIndex: 'numOfBookings',
            key: 'numOfBookings',
            render: (numOfBookings: string) => <span>{numOfBookings}</span>,
            sorter: (a, b) => a.numOfBookings - b.numOfBookings
        },
        {
            title: '# of cancellations',
            dataIndex: 'numOfCancellations',
            key: 'numOfCancellations',
            render: (numOfCancellations: string) => <span>{numOfCancellations}</span>,
            sorter: (a, b) => a.numOfCancellations - b.numOfCancellations
        },
        {
            title: '# of invited users',
            dataIndex: 'numOfInvitedUsers',
            key: 'numOfInvitedUsers',
            render: (numOfInvitedUsers: string) => <span>{numOfInvitedUsers}</span>,
            sorter: (a, b) => a.numOfInvitedUsers - b.numOfInvitedUsers
        },
        {
            title: 'Created',
            dataIndex: 'createdOn',
            key: 'createdOn',
            width: 150,
            render: (createdOn: string) => <span>{moment.utc(createdOn).format('DD/MM/YY HH:mm')}</span>,
            sorter: (a, b) => moment(a.createdOn).valueOf() - moment(b.createdOn).valueOf()
        }
    ]

    return (
        <div>
            <h1 className="text-2xl mb-2 font-bold">
                User "{user.firstName} {user.lastName}"
            </h1>

            <div>
                <a href={`tel:${user.phoneNumber.formattedNumber}`}>{user.phoneNumber.formattedNumber}</a>
            </div>
            <div className="mb-2">
                <a href={`mailto:${user.email}`} className="mb-2">
                    {user.email}
                </a>
            </div>

            <Table rowKey="id" columns={columns} dataSource={[user]} pagination={false} scroll={{ x: true }} />

            <div className="mt-4">
                <Bands data={userBands} hideCreatorColumn scroll={{ y: 300, x: true }} />
            </div>
            <div className="mt-4">
                <Payments data={userPayments} hideUserColumn scroll={{ y: 300 }} />
            </div>
            <div className="mt-4">
                <Bookings data={userBookings} hideUserColumn scroll={{ y: 300 }} />
            </div>
            <div className="mt-4">
                <InvitedUsers data={userInvitedUsers} hideUserColumn scroll={{ y: 300 }} />
            </div>
        </div>
    )
}
