import { SearchOutlined } from '@ant-design/icons'
import { InvitedUserVM, standardMomentFormat } from '@kazoo/shared'
import { Button, Input, Space, Table } from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import moment from 'moment'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppData } from '../../hooks/use-app-data'

interface InvitedUsersProps extends TableProps<InvitedUserVM> {
    data?: InvitedUserVM[]
    hideUserColumn?: boolean
}

export const InvitedUsers: React.FC<InvitedUsersProps> = ({ data, hideUserColumn, ...tableProps }) => {
    const { invitedUsers, users } = useAppData()
    const searchInput = useRef<Input>(null)
    const [{ searchedColumn }, setSearch] = useState({
        searchText: '',
        searchedColumn: ''
    })
    const dataToDisplay = data ?? invitedUsers

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearch({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex
        })
    }, [])

    const handleReset = useCallback(
        (clearFilters) => {
            clearFilters()
            setSearch({
                searchText: '',
                searchedColumn
            })
        },
        [searchedColumn]
    )

    const getColumnSearchProps = useCallback(
        (dataIndex: string | number) => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilterDropdownVisibleChange: (visible: boolean) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100)
                }
            }
        }),
        [handleSearch, handleReset]
    )

    const columns: ColumnsType<InvitedUserVM> = useMemo(
        () => [
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'user',
                fixed: 'left',
                width: 150,
                render: (email: string) => <span>{email}</span>,
                onFilter: (value: string | number | boolean, invitedUserVM: InvitedUserVM) =>
                    invitedUserVM.email.toLowerCase().includes(value.toString()),
                ...getColumnSearchProps('email')
            },
            {
                title: 'Invited by',
                dataIndex: 'invitedByUserId',
                key: 'invitedByUserId',
                render: (invitedByUserId: string) => {
                    const user = users.find((user) => user.id === invitedByUserId)
                    return user ? (
                        <Link className="text-blue-500" to={`/users/${user.id}`}>
                            {user.firstName}&nbsp;{user.lastName}
                        </Link>
                    ) : (
                        '-'
                    )
                },
                onFilter: (value: string | number | boolean, invitedUserVM: InvitedUserVM) => {
                    const user = users.find((user) => user.id === invitedUserVM.invitedByUserId)
                    return user ? (user.firstName + user.lastName).toLowerCase().includes(value.toString()) : false
                },
                ...getColumnSearchProps('invitedByUserId')
            },
            {
                title: 'Converted to registered user',
                dataIndex: 'convertedToUserId',
                key: 'convertedToUserId',
                render: (convertedToUserId: string) => {
                    const user = users.find((user) => user.id === convertedToUserId)
                    return user ? (
                        <Link className="text-blue-500" to={`/users/${user.id}`}>
                            {user.firstName}&nbsp;{user.lastName}
                        </Link>
                    ) : (
                        '-'
                    )
                },
                onFilter: (value: string | number | boolean, invitedUserVM: InvitedUserVM) => {
                    const user = users.find((user) => user.id === invitedUserVM.convertedToUserId)
                    return user ? (user.firstName + user.lastName).toLowerCase().includes(value.toString()) : false
                },
                ...getColumnSearchProps('convertedToUserId')
            },
            {
                title: 'Created',
                dataIndex: 'createdOn',
                key: 'createdOn',
                width: 150,
                render: (createdOn: string) => <span>{moment.utc(createdOn).format(standardMomentFormat)}</span>,
                sorter: (a, b) => moment(a.createdOn).valueOf() - moment(b.createdOn).valueOf()
            }
        ],
        [getColumnSearchProps, users]
    )

    return (
        <div>
            <h1 className="text-2xl mb-4 font-bold">Invited users</h1>
            <Table
                rowKey="email"
                columns={columns.filter((column) => {
                    if (column.key === 'invitedByUserId' && hideUserColumn) {
                        return false
                    }
                    return true
                })}
                dataSource={dataToDisplay}
                pagination={false}
                scroll={{ y: '60vh' }}
                {...tableProps}
            />
        </div>
    )
}
