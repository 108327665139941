import { DeleteOutlined, DownOutlined, SearchOutlined } from '@ant-design/icons'
import { BookingType, BookingVM, Room, roomName, toTitleCase } from "@kazoo/shared";
import { Button, Checkbox, Dropdown, Input, Menu, Popconfirm, Space, Table, Tag } from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import moment from 'moment'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppData } from '../../hooks/use-app-data'

interface BookingsProps extends TableProps<BookingVM> {
    data?: BookingVM[]
    hideUserColumn?: boolean
}

export const Bookings: React.FC<BookingsProps> = ({ data, hideUserColumn }) => {
    const { bookings } = useAppData()
    const searchInput = useRef<Input>(null)
    const [hideRefunded, setHideRefunded] = useState(true)
    const [hideAdminBlock, setHideAdminBlock] = useState(true)
    const [{ searchedColumn }, setSearch] = useState({
        searchText: '',
        searchedColumn: ''
    })
    const dataToDisplay = (data ?? bookings).filter((booking) => {
        if (hideAdminBlock && hideRefunded) {
            return booking.type !== BookingType.ADMIN_BLOCK && !booking.refunded
        } else if (hideAdminBlock){
            return booking.type !== BookingType.ADMIN_BLOCK
        } else if (hideRefunded) {
          return !booking.refunded
        }
        return true
    })

    const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearch({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex
        })
    }, [])

    const handleReset = useCallback(
        (clearFilters) => {
            clearFilters()
            setSearch({
                searchText: '',
                searchedColumn
            })
        },
        [searchedColumn]
    )

    const getColumnSearchProps = useCallback(
        (dataIndex: string | number) => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilterDropdownVisibleChange: (visible: boolean) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100)
                }
            }
        }),
        [handleReset, handleSearch]
    )

    const columns: ColumnsType<BookingVM> = useMemo(
        () => [
            {
                title: 'Booked by',
                dataIndex: 'user',
                key: 'user',
                render: (_, bookingVM: BookingVM) => (
                    <span>
                        <Link className="text-blue-500" to={`/users/${bookingVM.user.id}`}>
                            {bookingVM.user.firstName}&nbsp;{bookingVM.user.lastName}
                        </Link>
                    </span>
                ),
                sorter: (a, b) =>
                    (a.user.firstName + a.user.lastName).localeCompare(b.user.firstName + b.user.lastName),
                onFilter: (value: string | number | boolean, bookingVM: BookingVM) =>
                    (bookingVM.user.firstName + bookingVM.user.lastName).toLowerCase().includes(value.toString()),
                ...getColumnSearchProps('user')
            },
            {
                title: 'Start',
                dataIndex: 'startDate',
                key: 'startDate',
                sorter: (a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf(),
                render: (startDate: string) => <span>{moment(startDate).format('DD/MM/YY HH:mm')}</span>
            },
            {
                title: 'End',
                dataIndex: 'endDate',
                key: 'endDate',
                sorter: (a, b) => moment(a.endDate).valueOf() - moment(b.endDate).valueOf(),
                render: (endDate: string, bookingVM: BookingVM) => (
                    <span>
                        {moment(endDate).format('DD/MM/YY HH:mm')}&nbsp;(
                        <span className="font-semibold">
                            {moment(endDate).diff(moment(bookingVM.startDate), 'h')}h
                        </span>
                        )
                    </span>
                )
            },
            {
                title: 'Room',
                dataIndex: 'room',
                key: 'room',
                render: (room: Room) => <span>{roomName[room]}</span>,
                onFilter: (value: string | number | boolean, bookingVM: BookingVM) =>
                    roomName[bookingVM.room].toLowerCase().includes(value.toString()),
                ...getColumnSearchProps('room')
            },
            {
                title: 'Booking type',
                dataIndex: 'type',
                key: 'type',
                render: (type: string, bookingVM) => (
                    <span>
                        {toTitleCase(type)}
                        {bookingVM.bandName && bookingVM.bandId ? (
                            <Link className="text-blue-500" to={`/bands/${bookingVM.bandId}`}>
                                &nbsp;({bookingVM.bandName})
                            </Link>
                        ) : (
                            ''
                        )}
                    </span>
                )
            },
            {
                title: 'Refunded',
                dataIndex: 'refunded',
                key: 'refunded',
                render: (_, bookingVM) => (bookingVM.refunded ? <Tag color="orange">Refunded</Tag> : '-'),
                sorter: (a) => (a.refunded ? -1 : 1)
            },
            {
                title: '',
                dataIndex: '',
                key: 'actions',
                render: (_, bookingVM) => (
                    <Dropdown overlay={<ActionsMenu bookingVM={bookingVM} />} trigger={['click']}>
                        <Button>
                            Actions <DownOutlined />
                        </Button>
                    </Dropdown>
                )
            }
        ],
        [getColumnSearchProps]
    )

    return (
        <>
            <div className="flex items-center space-x-8 mb-4">
                <h1 className="text-2xl font-bold">Bookings</h1>
                <div className="space-x-2">
                    <Checkbox checked={hideRefunded} onChange={(e) => setHideRefunded(e.target.checked)}>
                        <span>Hide refunded</span>
                    </Checkbox>
                </div>
                <div className="space-x-2">
                  <Checkbox checked={hideAdminBlock} onChange={(e) => setHideAdminBlock(e.target.checked)}>
                    <span>Hide admin block</span>
                  </Checkbox>
                </div>
            </div>
            <Table
                rowKey="id"
                scroll={{ y: '70vh' }}
                columns={columns.filter((column) => {
                    return !(column.key === 'user' && hideUserColumn);
                })}
                dataSource={dataToDisplay}
                pagination={false}
            />
        </>
    )
}

interface ActionsMenuProps {
    bookingVM: BookingVM
}

const ActionsMenu: React.FC<ActionsMenuProps> = ({ bookingVM }) => {
    const cancelAndRefund = useCallback(() => {}, [])

    return (
        <Menu>
            <Menu.Item key="1">
                <Popconfirm
                    placement="topLeft"
                    title="Are you sure you want to cancel this booking and refund the credits?"
                    onConfirm={cancelAndRefund}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined />
                    Cancel and refund credits
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )
}
